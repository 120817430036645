import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import invariant from 'tiny-invariant';
import { repositoriesAtom } from './jotai-atoms';
import { getRepositories } from './api-services';
import { displayErrorToast } from '~/components/ui/use-toast';

const useRepositories = () => {
  const [repositories, setRepositories] = useAtom(repositoriesAtom);

  const fetchRepositories = useCallback(async () => {
    try {
      // Fetch repositories excluding counters, risk score and data sinks
      const responseData = await getRepositories();
      invariant(
        responseData?.items,
        'An error occurred while fetching Repositories',
      );
      const sortedRepositories = [...responseData.items];
      sortedRepositories.sort((a, b) => a.name.localeCompare(b.name));
      responseData.items = sortedRepositories;
      setRepositories(responseData.items);
    } catch (error) {
      displayErrorToast(error);
    }
  }, [setRepositories]);

  useEffect(() => {
    if (repositories === null) {
      fetchRepositories();
    }
  }, [fetchRepositories, repositories]);

  return {
    repositories,
    fetchRepositories,
  } as const;
};

export { useRepositories };
